import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiFilePdf, mdiFolder } from '@mdi/js';
import { Home } from '@material-ui/icons';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { rogramaMejoraDocuments, programaMejoraFuente, programaMejoraUpdatedAt } from '../../utils/transparencia-difusion/constants';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import { useGlobalStyles } from '../../utils/styles';
import { escala_gris } from '../../utils/constants';

const ProgramaAgendaMejoraRegulatoria = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const [fecha, setFecha] = useState<string>(programaMejoraUpdatedAt);
    const [data, setData] = useState<IListDocs[]>(rogramaMejoraDocuments);

    return (
        <Layout>
          <SEO title='Programa y Agenda Mejora Regulatoria' />
    
          <div className={globalClasses.container}>
            <Header title='Programa y agenda mejora regulatoria' />
    
            <div className={globalClasses.content}>
              <Typography>Aquí puedes descargar y consultar, Programa y Agenda Mejora Regulatoria del Instituto de Seguridad Social del Estado de Tabasco:</Typography>
    
              <List className={classes.list}>
                {!data[0].root ? (
                  <Button
                    startIcon={<Home />}
                    variant="outlined"
                    color="primary"
                    onClick={() => { setData(rogramaMejoraDocuments); setFecha(programaMejoraUpdatedAt) }}
                    style={{ marginBottom: 20 }}>
                    Inicio
                  </Button>
                ) : null}
    
                {data.map((document, index) => (
                  document.elements ?
                    (
                      <ListItem key={index} button onClick={() => { setData(document?.elements || []); setFecha(document?.updtedAt || '') }}>
                        <ListItemAvatar>
                          <Icon path={mdiFolder} size={1.7} color={escala_gris ? '#767576' : '#fad390'} />
                        </ListItemAvatar>
                        <ListItemText primary={document.name} />
                      </ListItem>
                    ) : (
                      <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                        <ListItem button>
                          <ListItemAvatar>
                            <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'} />
                          </ListItemAvatar>
                          <ListItemText primary={document.name} secondary={document.size} />
                        </ListItem>
                      </a>
                    )
                ))}
              </List>
    
              <UpdatedInfo fuente={programaMejoraFuente} updatedAt={fecha} />
            </div>
          </div>
        </Layout>
      );
    };
    
    export default ProgramaAgendaMejoraRegulatoria;